import { Injectable } from '@angular/core';
import { SitesService } from '@app/business/sites/sites.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CommonDataService {

	productionDomain = 'www.iotemeter.com';
	stagingDomain = 'iotemeter.com';
	developmentDomain = 'iotemeter.org';

	showRefreshMessage: boolean = false;
	TimeZonesMenu: {
		display_name: string,
		id: number,
		base_utc: number,
		changes_time: number[],
		changes_value: number
	}[] = [
			{ display_name: '(UTC-12:00) International Date Line West', id: 1, base_utc: -43200, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC-11:00) Coordinated Universal Time-11', id: 2, base_utc: -39600, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC-10:00) Hawaii', id: 3, base_utc: -36000, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC-09:00) Alaska', id: 4, base_utc: -32400, changes_time: [1425812400, 1446375600, 1457866800, 1478430000, 1489316400, 1509879600, 1520766000, 1541329200, 1552215600, 1572778800, 1583665200, 1604228400, 1615719600, 1636282800, 1647169200, 1667732400, 1678618800, 1699182000, 1710068400, 1730631600, 1741518000, 1762081200, 1772967600, 1793530800, 1805022000, 1825585200, 1836471600, 1857034800, 1867921200, 1888484400, 1899370800, 1919934000, 1930820400, 1951383600, 1962874800, 1983438000, 1994324400, 2014887600, 2025774000, 2046337200, 2057223600, 2077786800, 2088673200, 2109236400, 2120122800, 2140686000, 2152177200, 2172740400, 2183626800, 2204190000], changes_value: 3600 },
			{ display_name: '(UTC-08:00) Baja California', id: 5, base_utc: -28800, changes_time: [1428228000, 1445767200, 1459677600, 1477821600, 1491127200, 1509271200, 1522576800, 1540720800, 1554631200, 1572170400, 1586080800, 1603620000, 1617530400, 1635674400, 1648980000, 1667124000, 1680429600, 1698573600, 1712484000, 1730023200, 1743933600, 1761472800, 1775383200, 1792922400, 1806832800, 1824976800, 1838282400, 1856426400, 1869732000, 1887876000, 1901786400, 1919325600, 1933236000, 1950775200, 1964685600, 1982829600, 1996135200, 2014279200, 2027584800, 2045728800, 2059034400, 2077178400, 2091088800, 2108628000, 2122538400, 2140077600, 2153988000, 2172132000, 2185437600, 2203581600], changes_value: 3600 },
			{ display_name: '(UTC-08:00) Pacific Time (US & Canada)', id: 6, base_utc: -28800, changes_time: [1425808800, 1446372000, 1457863200, 1478426400, 1489312800, 1509876000, 1520762400, 1541325600, 1552212000, 1572775200, 1583661600, 1604224800, 1615716000, 1636279200, 1647165600, 1667728800, 1678615200, 1699178400, 1710064800, 1730628000, 1741514400, 1762077600, 1772964000, 1793527200, 1805018400, 1825581600, 1836468000, 1857031200, 1867917600, 1888480800, 1899367200, 1919930400, 1930816800, 1951380000, 1962871200, 1983434400, 1994320800, 2014884000, 2025770400, 2046333600, 2057220000, 2077783200, 2088669600, 2109232800, 2120119200, 2140682400, 2152173600, 2172736800, 2183623200, 2204186400], changes_value: 3600 },
			{ display_name: '(UTC-07:00) Arizona', id: 7, base_utc: -25200, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC-07:00) Chihuahua, La Paz, Mazatlan', id: 8, base_utc: -25200, changes_time: [1428224400, 1445763600, 1459674000, 1477818000, 1491123600, 1509267600, 1522573200, 1540717200, 1554627600, 1572166800, 1586077200, 1603616400, 1617526800, 1635670800, 1648976400, 1667120400, 1680426000, 1698570000, 1712480400, 1730019600, 1743930000, 1761469200, 1775379600, 1792918800, 1806829200, 1824973200, 1838278800, 1856422800, 1869728400, 1887872400, 1901782800, 1919322000, 1933232400, 1950771600, 1964682000, 1982826000, 1996131600, 2014275600, 2027581200, 2045725200, 2059030800, 2077174800, 2091085200, 2108624400, 2122534800, 2140074000, 2153984400, 2172128400, 2185434000, 2203578000], changes_value: 3600 },
			{ display_name: '(UTC-07:00) Mountain Time (US & Canada)', id: 9, base_utc: -25200, changes_time: [1425805200, 1446368400, 1457859600, 1478422800, 1489309200, 1509872400, 1520758800, 1541322000, 1552208400, 1572771600, 1583658000, 1604221200, 1615712400, 1636275600, 1647162000, 1667725200, 1678611600, 1699174800, 1710061200, 1730624400, 1741510800, 1762074000, 1772960400, 1793523600, 1805014800, 1825578000, 1836464400, 1857027600, 1867914000, 1888477200, 1899363600, 1919926800, 1930813200, 1951376400, 1962867600, 1983430800, 1994317200, 2014880400, 2025766800, 2046330000, 2057216400, 2077779600, 2088666000, 2109229200, 2120115600, 2140678800, 2152170000, 2172733200, 2183619600, 2204182800], changes_value: 3600 },
			{ display_name: '(UTC-06:00) Central America', id: 10, base_utc: -21600, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC-06:00) Central Time (US & Canada)', id: 11, base_utc: -21600, changes_time: [1425801600, 1446364800, 1457856000, 1478419200, 1489305600, 1509868800, 1520755200, 1541318400, 1552204800, 1572768000, 1583654400, 1604217600, 1615708800, 1636272000, 1647158400, 1667721600, 1678608000, 1699171200, 1710057600, 1730620800, 1741507200, 1762070400, 1772956800, 1793520000, 1805011200, 1825574400, 1836460800, 1857024000, 1867910400, 1888473600, 1899360000, 1919923200, 1930809600, 1951372800, 1962864000, 1983427200, 1994313600, 2014876800, 2025763200, 2046326400, 2057212800, 2077776000, 2088662400, 2109225600, 2120112000, 2140675200, 2152166400, 2172729600, 2183616000, 2204179200], changes_value: 3600 },
			{ display_name: '(UTC-06:00) Guadalajara, Mexico City, Monterrey', id: 12, base_utc: -21600, changes_time: [1428220800, 1445760000, 1459670400, 1477814400, 1491120000, 1509264000, 1522569600, 1540713600, 1554624000, 1572163200, 1586073600, 1603612800, 1617523200, 1635667200, 1648972800, 1667116800, 1680422400, 1698566400, 1712476800, 1730016000, 1743926400, 1761465600, 1775376000, 1792915200, 1806825600, 1824969600, 1838275200, 1856419200, 1869724800, 1887868800, 1901779200, 1919318400, 1933228800, 1950768000, 1964678400, 1982822400, 1996128000, 2014272000, 2027577600, 2045721600, 2059027200, 2077171200, 2091081600, 2108620800, 2122531200, 2140070400, 2153980800, 2172124800, 2185430400, 2203574400], changes_value: 3600 },
			{ display_name: '(UTC-06:00) Saskatchewan', id: 13, base_utc: -21600, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco', id: 14, base_utc: -18000, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC-05:00) Eastern Time (US & Canada)', id: 15, base_utc: -18000, changes_time: [1425798000, 1446361200, 1457852400, 1478415600, 1489302000, 1509865200, 1520751600, 1541314800, 1552201200, 1572764400, 1583650800, 1604214000, 1615705200, 1636268400, 1647154800, 1667718000, 1678604400, 1699167600, 1710054000, 1730617200, 1741503600, 1762066800, 1772953200, 1793516400, 1805007600, 1825570800, 1836457200, 1857020400, 1867906800, 1888470000, 1899356400, 1919919600, 1930806000, 1951369200, 1962860400, 1983423600, 1994310000, 2014873200, 2025759600, 2046322800, 2057209200, 2077772400, 2088658800, 2109222000, 2120108400, 2140671600, 2152162800, 2172726000, 2183612400, 2204175600], changes_value: 3600 },
			{ display_name: '(UTC-05:00) Indiana (East)', id: 16, base_utc: -18000, changes_time: [1425798000, 1446361200, 1457852400, 1478415600, 1489302000, 1509865200, 1520751600, 1541314800, 1552201200, 1572764400, 1583650800, 1604214000, 1615705200, 1636268400, 1647154800, 1667718000, 1678604400, 1699167600, 1710054000, 1730617200, 1741503600, 1762066800, 1772953200, 1793516400, 1805007600, 1825570800, 1836457200, 1857020400, 1867906800, 1888470000, 1899356400, 1919919600, 1930806000, 1951369200, 1962860400, 1983423600, 1994310000, 2014873200, 2025759600, 2046322800, 2057209200, 2077772400, 2088658800, 2109222000, 2120108400, 2140671600, 2152162800, 2172726000, 2183612400, 2204175600], changes_value: 3600 },
			{ display_name: '(UTC-04:30) Caracas', id: 17, base_utc: -16200, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC-04:00) Asuncion', id: 18, base_utc: -14400, changes_time: [1443931199, 1426996799, 1475380799, 1459051199, 1506830399, 1490500799, 1538884799, 1521950399, 1570334399, 1553399999, 1601783999, 1584849599, 1633233599, 1616903999, 1664683199, 1648353599, 1696737599, 1679803199, 1728187199, 1711857599, 1759636799, 1743307199, 1791086399, 1774756799, 1822535999, 1806206399, 1854590399, 1837655999, 1886039999, 1869710399, 1917489599, 1901159999, 1948939199, 1932609599, 1980388799, 1964059199, 2011838399, 1995508799, 2043892799, 2026958399, 2075342399, 2059012799, 2106791999, 2090462399, 2138241599, 2121911999, 2169691199, 2153361599, 2201140799, 2184811199], changes_value: 3600 },
			{ display_name: '(UTC-04:00) Atlantic Time (Canada)', id: 19, base_utc: -14400, changes_time: [1425794400, 1446357600, 1457848800, 1478412000, 1489298400, 1509861600, 1520748000, 1541311200, 1552197600, 1572760800, 1583647200, 1604210400, 1615701600, 1636264800, 1647151200, 1667714400, 1678600800, 1699164000, 1710050400, 1730613600, 1741500000, 1762063200, 1772949600, 1793512800, 1805004000, 1825567200, 1836453600, 1857016800, 1867903200, 1888466400, 1899352800, 1919916000, 1930802400, 1951365600, 1962856800, 1983420000, 1994306400, 2014869600, 2025756000, 2046319200, 2057205600, 2077768800, 2088655200, 2109218400, 2120104800, 2140668000, 2152159200, 2172722400, 2183608800, 2204172000], changes_value: 3600 },
			{ display_name: '(UTC-04:00) Cuiaba', id: 20, base_utc: -14400, changes_time: [1445140799, 1424577599, 1476590399, 1456027199, 1508039999, 1487476799, 1540094399, 1518926399, 1571543999, 1550375999, 1602993599, 1581825599, 1634443199, 1613879999, 1665892799, 1645329599, 1697342399, 1677383999, 1729396799, 1708228799, 1760846399, 1739678399, 1792295999, 1771732799, 1823745599, 1803182399, 1855195199, 1834631999, 1887249599, 1866081599, 1918699199, 1897531199, 1950148799, 1928980799, 1981598399, 1960430399, 2013047999, 1992484799, 2044497599, 2024539199, 2076551999, 2055383999, 2108001599, 2086833599, 2139451199, 2118887999, 2170900799, 2150337599, 2202350399, 2182391999], changes_value: 3600 },
			{ display_name: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan', id: 21, base_utc: -14400, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC-04:00) Santiago', id: 22, base_utc: -14400, changes_time: [1444535999, 1426391999, 1475985599, 1457841599, 1508039999, 1489291199, 1539489599, 1520740799, 1570939199, 1552190399, 1602388799, 1584244799, 1633838399, 1615694399, 1665287999, 1647143999, 1697342399, 1678593599, 1728791999, 1710043199, 1760241599, 1741492799, 1791691199, 1773547199, 1823140799, 1804996799, 1855195199, 1836446399, 1886644799, 1867895999, 1918094399, 1899345599, 1949543999, 1930795199, 1980993599, 1962849599, 2012443199, 1994299199, 2044497599, 2025748799, 2075947199, 2057198399, 2107396799, 2088647999, 2138846399, 2120702399, 2170295999, 2152151999, 2201745599, 2183601599], changes_value: 3600 },
			{ display_name: '(UTC-03:30) Newfoundland', id: 23, base_utc: -12600, changes_time: [1425792600, 1446355800, 1457847000, 1478410200, 1489296600, 1509859800, 1520746200, 1541309400, 1552195800, 1572759000, 1583645400, 1604208600, 1615699800, 1636263000, 1647149400, 1667712600, 1678599000, 1699162200, 1710048600, 1730611800, 1741498200, 1762061400, 1772947800, 1793511000, 1805002200, 1825565400, 1836451800, 1857015000, 1867901400, 1888464600, 1899351000, 1919914200, 1930800600, 1951363800, 1962855000, 1983418200, 1994304600, 2014867800, 2025754200, 2046317400, 2057203800, 2077767000, 2088653400, 2109216600, 2120103000, 2140666200, 2152157400, 2172720600, 2183607000, 2204170200], changes_value: 3600 },
			{ display_name: '(UTC-03:00) Brasilia', id: 24, base_utc: -10800, changes_time: [1445137199, 1424573999, 1476586799, 1456023599, 1508036399, 1487473199, 1540090799, 1518922799, 1571540399, 1550372399, 1602989999, 1581821999, 1634439599, 1613876399, 1665889199, 1645325999, 1697338799, 1677380399, 1729393199, 1708225199, 1760842799, 1739674799, 1792292399, 1771729199, 1823741999, 1803178799, 1855191599, 1834628399, 1887245999, 1866077999, 1918695599, 1897527599, 1950145199, 1928977199, 1981594799, 1960426799, 2013044399, 1992481199, 2044493999, 2024535599, 2076548399, 2055380399, 2107997999, 2086829999, 2139447599, 2118884399, 2170897199, 2150333999, 2202346799, 2182388399], changes_value: 3600 },
			{ display_name: '(UTC-03:00) Buenos Aires', id: 25, base_utc: -10800, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC-03:00) Cayenne, Fortaleza', id: 26, base_utc: -10800, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC-03:00) Greenland', id: 27, base_utc: -10800, changes_time: [1427590800, 1445738400, 1459040400, 1477792800, 1490490000, 1509242400, 1521939600, 1540692000, 1553994000, 1572141600, 1585443600, 1603591200, 1616893200, 1635645600, 1648342800, 1667095200, 1679792400, 1698544800, 1711846800, 1729994400, 1743296400, 1761444000, 1774746000, 1793498400, 1806195600, 1824948000, 1837645200, 1856397600, 1869699600, 1887847200, 1901149200, 1919296800, 1932598800, 1950746400, 1964048400, 1982800800, 1995498000, 2014250400, 2026947600, 2045700000, 2059002000, 2077149600, 2090451600, 2108599200, 2121901200, 2140653600, 2153350800, 2172103200, 2184800400, 2203552800], changes_value: 3600 },
			{ display_name: '(UTC-03:00) Montevideo', id: 28, base_utc: -10800, changes_time: [1443934800, 1425790800, 1475384400, 1457845200, 1506834000, 1489294800, 1538888400, 1520744400, 1570338000, 1552194000, 1601787600, 1583643600, 1633237200, 1615698000, 1664686800, 1647147600, 1696136400, 1678597200, 1728190800, 1710046800, 1759640400, 1741496400, 1791090000, 1772946000, 1822539600, 1805000400, 1853989200, 1836450000, 1886043600, 1867899600, 1917493200, 1899349200, 1948942800, 1930798800, 1980392400, 1962853200, 2011842000, 1994302800, 2043291600, 2025752400, 2075346000, 2057202000, 2106795600, 2088651600, 2138245200, 2120101200, 2169694800, 2152155600, 2201144400, 2183605200], changes_value: 3600 },
			{ display_name: '(UTC-03:00) Salvador', id: 29, base_utc: -10800, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC-02:00) Coordinated Universal Time-02', id: 30, base_utc: -7200, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC-02:00) Mid-Atlantic - Old', id: 31, base_utc: -7200, changes_time: [1427601600, 1443326400, 1459051200, 1474776000, 1490500800, 1506225600, 1521950400, 1538280000, 1554004800, 1569729600, 1585454400, 1601179200, 1616904000, 1632628800, 1648353600, 1664078400, 1679803200, 1695528000, 1711857600, 1727582400, 1743307200, 1759032000, 1774756800, 1790481600, 1806206400, 1821931200, 1837656000, 1853380800, 1869105600, 1885435200, 1901160000, 1916884800, 1932609600, 1948334400, 1964059200, 1979784000, 1995508800, 2011233600, 2026958400, 2042683200, 2058408000, 2074737600, 2090462400, 2106187200, 2121912000, 2137636800, 2153361600, 2169086400, 2184811200, 2200536000], changes_value: 3600 },
			{ display_name: '(UTC-01:00) Azores', id: 32, base_utc: -3600, changes_time: [1427590800, 1445738400, 1459040400, 1477792800, 1490490000, 1509242400, 1521939600, 1540692000, 1553994000, 1572141600, 1585443600, 1603591200, 1616893200, 1635645600, 1648342800, 1667095200, 1679792400, 1698544800, 1711846800, 1729994400, 1743296400, 1761444000, 1774746000, 1792893600, 1806195600, 1824948000, 1837645200, 1856397600, 1869094800, 1887847200, 1901149200, 1919296800, 1932598800, 1950746400, 1964048400, 1982800800, 1995498000, 2014250400, 2026947600, 2045700000, 2058397200, 2077149600, 2090451600, 2108599200, 2121901200, 2140048800, 2153350800, 2172103200, 2184800400, 2203552800], changes_value: 3600 },
			{ display_name: '(UTC-01:00) Cabo Verde Is.', id: 33, base_utc: -3600, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC) Casablanca', id: 34, base_utc: 0, changes_time: [1427594400, 1445742000, 1459044000, 1477796400, 1490493600, 1509246000, 1521943200, 1540695600, 1553997600, 1572145200, 1585447200, 1603594800, 1616896800, 1635649200, 1648346400, 1667098800, 1679796000, 1698548400, 1711850400, 1729998000, 1743300000, 1761447600, 1774749600, 1792897200, 1806199200, 1824951600, 1837648800, 1856401200, 1869098400, 1887850800, 1901152800, 1919300400, 1932602400, 1950750000, 1964052000, 1982804400, 1995501600, 2014254000, 2026951200, 2045703600, 2058400800, 2077153200, 2090455200, 2108602800, 2121904800, 2140052400, 2153354400, 2172106800, 2184804000, 2203556400], changes_value: 3600 },
			{ display_name: '(UTC) Coordinated Universal Time', id: 35, base_utc: 0, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC) Dublin, Edinburgh, Lisbon, London', id: 36, base_utc: 0, changes_time: [1427590800, 1445738400, 1459040400, 1477792800, 1490490000, 1509242400, 1521939600, 1540692000, 1553994000, 1572141600, 1585443600, 1603591200, 1616893200, 1635645600, 1648342800, 1667095200, 1679792400, 1698544800, 1711846800, 1729994400, 1743296400, 1761444000, 1774746000, 1792893600, 1806195600, 1824948000, 1837645200, 1856397600, 1869094800, 1887847200, 1901149200, 1919296800, 1932598800, 1950746400, 1964048400, 1982800800, 1995498000, 2014250400, 2026947600, 2045700000, 2058397200, 2077149600, 2090451600, 2108599200, 2121901200, 2140048800, 2153350800, 2172103200, 2184800400, 2203552800], changes_value: 3600 },
			{ display_name: '(UTC) Monrovia, Reykjavik', id: 37, base_utc: 0, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', id: 38, base_utc: 3600, changes_time: [1427590800, 1445738400, 1459040400, 1477792800, 1490490000, 1509242400, 1521939600, 1540692000, 1553994000, 1572141600, 1585443600, 1603591200, 1616893200, 1635645600, 1648342800, 1667095200, 1679792400, 1698544800, 1711846800, 1729994400, 1743296400, 1761444000, 1774746000, 1792893600, 1806195600, 1824948000, 1837645200, 1856397600, 1869094800, 1887847200, 1901149200, 1919296800, 1932598800, 1950746400, 1964048400, 1982800800, 1995498000, 2014250400, 2026947600, 2045700000, 2058397200, 2077149600, 2090451600, 2108599200, 2121901200, 2140048800, 2153350800, 2172103200, 2184800400, 2203552800], changes_value: 3600 },
			{ display_name: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague', id: 39, base_utc: 3600, changes_time: [1427590800, 1445738400, 1459040400, 1477792800, 1490490000, 1509242400, 1521939600, 1540692000, 1553994000, 1572141600, 1585443600, 1603591200, 1616893200, 1635645600, 1648342800, 1667095200, 1679792400, 1698544800, 1711846800, 1729994400, 1743296400, 1761444000, 1774746000, 1792893600, 1806195600, 1824948000, 1837645200, 1856397600, 1869094800, 1887847200, 1901149200, 1919296800, 1932598800, 1950746400, 1964048400, 1982800800, 1995498000, 2014250400, 2026947600, 2045700000, 2058397200, 2077149600, 2090451600, 2108599200, 2121901200, 2140048800, 2153350800, 2172103200, 2184800400, 2203552800], changes_value: 3600 },
			{ display_name: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris', id: 40, base_utc: 3600, changes_time: [1427590800, 1445738400, 1459040400, 1477792800, 1490490000, 1509242400, 1521939600, 1540692000, 1553994000, 1572141600, 1585443600, 1603591200, 1616893200, 1635645600, 1648342800, 1667095200, 1679792400, 1698544800, 1711846800, 1729994400, 1743296400, 1761444000, 1774746000, 1792893600, 1806195600, 1824948000, 1837645200, 1856397600, 1869094800, 1887847200, 1901149200, 1919296800, 1932598800, 1950746400, 1964048400, 1982800800, 1995498000, 2014250400, 2026947600, 2045700000, 2058397200, 2077149600, 2090451600, 2108599200, 2121901200, 2140048800, 2153350800, 2172103200, 2184800400, 2203552800], changes_value: 3600 },
			{ display_name: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb', id: 41, base_utc: 3600, changes_time: [1427590800, 1445738400, 1459040400, 1477792800, 1490490000, 1509242400, 1521939600, 1540692000, 1553994000, 1572141600, 1585443600, 1603591200, 1616893200, 1635645600, 1648342800, 1667095200, 1679792400, 1698544800, 1711846800, 1729994400, 1743296400, 1761444000, 1774746000, 1792893600, 1806195600, 1824948000, 1837645200, 1856397600, 1869094800, 1887847200, 1901149200, 1919296800, 1932598800, 1950746400, 1964048400, 1982800800, 1995498000, 2014250400, 2026947600, 2045700000, 2058397200, 2077149600, 2090451600, 2108599200, 2121901200, 2140048800, 2153350800, 2172103200, 2184800400, 2203552800], changes_value: 3600 },
			{ display_name: '(UTC+01:00) West Central Africa', id: 42, base_utc: 3600, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+01:00) Windhoek', id: 43, base_utc: 3600, changes_time: [1441501200, 1428195600, 1472950800, 1459645200, 1504400400, 1491094800, 1535850000, 1522544400, 1567299600, 1554598800, 1599354000, 1586048400, 1630803600, 1617498000, 1662253200, 1648947600, 1693702800, 1680397200, 1725152400, 1712451600, 1757206800, 1743901200, 1788656400, 1775350800, 1820106000, 1806800400, 1851555600, 1838250000, 1883005200, 1869699600, 1914454800, 1901754000, 1946509200, 1933203600, 1977958800, 1964653200, 2009408400, 1996102800, 2040858000, 2027552400, 2072307600, 2059002000, 2104362000, 2091056400, 2135811600, 2122506000, 2167261200, 2153955600, 2198710800, 2185405200], changes_value: 3600 },
			{ display_name: '(UTC+02:00) Amman', id: 44, base_utc: 7200, changes_time: [1427407199, 1446159600, 1459461599, 1477609200, 1490911199, 1509058800, 1522360799, 1540508400, 1553810399, 1571958000, 1585259999, 1604012400, 1616709599, 1635462000, 1648763999, 1666911600, 1680213599, 1698361200, 1711663199, 1729810800, 1743112799, 1761865200, 1774562399, 1793314800, 1806011999, 1824764400, 1838066399, 1856214000, 1869515999, 1887663600, 1900965599, 1919113200, 1932415199, 1951167600, 1963864799, 1982617200, 1995919199, 2014066800, 2027368799, 2045516400, 2058818399, 2076966000, 2090267999, 2109020400, 2121717599, 2140470000, 2153167199, 2171919600, 2185221599, 2203369200], changes_value: 3600 },
			{ display_name: '(UTC+02:00) Athens, Bucharest', id: 45, base_utc: 7200, changes_time: [1427590800, 1445738400, 1459040400, 1477792800, 1490490000, 1509242400, 1521939600, 1540692000, 1553994000, 1572141600, 1585443600, 1603591200, 1616893200, 1635645600, 1648342800, 1667095200, 1679792400, 1698544800, 1711846800, 1729994400, 1743296400, 1761444000, 1774746000, 1792893600, 1806195600, 1824948000, 1837645200, 1856397600, 1869094800, 1887847200, 1901149200, 1919296800, 1932598800, 1950746400, 1964048400, 1982800800, 1995498000, 2014250400, 2026947600, 2045700000, 2058397200, 2077149600, 2090451600, 2108599200, 2121901200, 2140048800, 2153350800, 2172103200, 2184800400, 2203552800], changes_value: 3600 },
			{ display_name: '(UTC+02:00) Beirut', id: 46, base_utc: 7200, changes_time: [1427579999, 1445723999, 1459029599, 1477778399, 1490479199, 1509227999, 1521928799, 1540677599, 1553983199, 1572127199, 1585432799, 1603576799, 1616882399, 1635631199, 1648331999, 1667080799, 1679781599, 1698530399, 1711835999, 1729979999, 1743285599, 1761429599, 1774735199, 1793483999, 1806184799, 1824933599, 1837634399, 1856383199, 1869688799, 1887832799, 1901138399, 1919282399, 1932587999, 1950731999, 1964037599, 1982786399, 1995487199, 2014235999, 2026936799, 2045685599, 2058991199, 2077135199, 2090440799, 2108584799, 2121890399, 2140639199, 2153339999, 2172088799, 2184789599, 2203538399], changes_value: 3600 },
			{ display_name: '(UTC+02:00) Cairo', id: 47, base_utc: 7200, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+02:00) Damascus', id: 48, base_utc: 7200, changes_time: [1428011999, 1446155999, 1459461599, 1477605599, 1491515999, 1509055199, 1522965599, 1540504799, 1554415199, 1572559199, 1585864799, 1604008799, 1617314399, 1635458399, 1649368799, 1666907999, 1680818399, 1698357599, 1712267999, 1730411999, 1743717599, 1761861599, 1775167199, 1793311199, 1806616799, 1824760799, 1838671199, 1856210399, 1870120799, 1887659999, 1901570399, 1919714399, 1933019999, 1951163999, 1964469599, 1982613599, 1996523999, 2014063199, 2027973599, 2045512799, 2059423199, 2076962399, 2090872799, 2109016799, 2122322399, 2140466399, 2153771999, 2171915999, 2185826399, 2203365599], changes_value: 3600 },
			{ display_name: '(UTC+02:00) E. Europe', id: 49, base_utc: 7200, changes_time: [1427587200, 1445734800, 1459036800, 1477789200, 1490486400, 1509238800, 1521936000, 1540688400, 1553990400, 1572138000, 1585440000, 1603587600, 1616889600, 1635642000, 1648339200, 1667091600, 1679788800, 1698541200, 1711843200, 1729990800, 1743292800, 1761440400, 1774742400, 1792890000, 1806192000, 1824944400, 1837641600, 1856394000, 1869091200, 1887843600, 1901145600, 1919293200, 1932595200, 1950742800, 1964044800, 1982797200, 1995494400, 2014246800, 2026944000, 2045696400, 2058393600, 2077146000, 2090448000, 2108595600, 2121897600, 2140045200, 2153347200, 2172099600, 2184796800, 2203549200], changes_value: 3600 },
			{ display_name: '(UTC+02:00) Harare, Pretoria', id: 50, base_utc: 7200, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', id: 51, base_utc: 7200, changes_time: [1427590800, 1445738400, 1459040400, 1477792800, 1490490000, 1509242400, 1521939600, 1540692000, 1553994000, 1572141600, 1585443600, 1603591200, 1616893200, 1635645600, 1648342800, 1667095200, 1679792400, 1698544800, 1711846800, 1729994400, 1743296400, 1761444000, 1774746000, 1792893600, 1806195600, 1824948000, 1837645200, 1856397600, 1869094800, 1887847200, 1901149200, 1919296800, 1932598800, 1950746400, 1964048400, 1982800800, 1995498000, 2014250400, 2026947600, 2045700000, 2058397200, 2077149600, 2090451600, 2108599200, 2121901200, 2140048800, 2153350800, 2172103200, 2184800400, 2203552800], changes_value: 3600 },
			{ display_name: '(UTC+02:00) Istanbul', id: 52, base_utc: 7200, changes_time: [1427590800, 1445738400, 1459040400, 1477792800, 1490490000, 1509242400, 1521939600, 1540692000, 1553994000, 1572141600, 1585443600, 1603591200, 1616893200, 1635645600, 1648342800, 1667095200, 1679792400, 1698544800, 1711846800, 1729994400, 1743296400, 1761444000, 1774746000, 1792893600, 1806195600, 1824948000, 1837645200, 1856397600, 1869094800, 1887847200, 1901149200, 1919296800, 1932598800, 1950746400, 1964048400, 1982800800, 1995498000, 2014250400, 2026947600, 2045700000, 2058397200, 2077149600, 2090451600, 2108599200, 2121901200, 2140048800, 2153350800, 2172103200, 2184800400, 2203552800], changes_value: 3600 },
			{ display_name: '(UTC+02:00) Jerusalem', id: 53, base_utc: 7200, changes_time: [1427414400, 1445731200, 1458864000, 1477785600, 1490313600, 1509235200, 1521763200, 1540684800, 1553817600, 1572134400, 1585267200, 1603584000, 1616716800, 1635638400, 1648166400, 1667088000, 1679616000, 1698537600, 1711065600, 1729987200, 1743120000, 1761436800, 1774569600, 1792886400, 1806019200, 1824940800, 1837468800, 1856390400, 1868918400, 1887840000, 1900368000, 1919289600, 1932422400, 1950739200, 1963872000, 1982793600, 1995321600, 2014243200, 2026771200, 2045692800, 2058220800, 2077142400, 2090275200, 2108592000, 2121724800, 2140041600, 2153174400, 2172096000, 2184624000, 2203545600], changes_value: 3600 },
			{ display_name: '(UTC+02:00) Kaliningrad (RTZ 1)', id: 54, base_utc: 7200, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+02:00) Tripoli', id: 55, base_utc: 7200, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+03:00) Baghdad', id: 56, base_utc: 10800, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+03:00) Kuwait, Riyadh', id: 57, base_utc: 10800, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+03:00) Minsk', id: 58, base_utc: 10800, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+03:00) Moscow, St. Petersburg, Volgograd (RTZ 2)', id: 59, base_utc: 10800, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+03:00) Nairobi', id: 60, base_utc: 10800, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+03:30) Tehran', id: 61, base_utc: 12600, changes_time: [1426969799, 1442867399, 1458419399, 1474316999, 1489868999, 1505766599, 1521318599, 1537216199, 1552768199, 1568665799, 1584822599, 1600720199, 1616272199, 1632169799, 1647721799, 1663619399, 1679171399, 1695068999, 1710620999, 1726518599, 1742070599, 1757968199, 1774124999, 1790022599, 1805574599, 1821472199, 1837024199, 1852921799, 1868473799, 1884371399, 1899923399, 1915820999, 1931372999, 1947270599, 1963427399, 1979324999, 1994876999, 2010774599, 2026326599, 2042224199, 2057776199, 2073673799, 2089225799, 2105123399, 2121280199, 2137177799, 2152729799, 2168627399, 2184179399, 2200076999], changes_value: 3600 },
			{ display_name: '(UTC+04:00) Abu Dhabi, Muscat', id: 62, base_utc: 14400, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+04:00) Baku', id: 63, base_utc: 14400, changes_time: [1427587200, 1445734800, 1459036800, 1477789200, 1490486400, 1509238800, 1521936000, 1540688400, 1553990400, 1572138000, 1585440000, 1603587600, 1616889600, 1635642000, 1648339200, 1667091600, 1679788800, 1698541200, 1711843200, 1729990800, 1743292800, 1761440400, 1774742400, 1792890000, 1806192000, 1824944400, 1837641600, 1856394000, 1869091200, 1887843600, 1901145600, 1919293200, 1932595200, 1950742800, 1964044800, 1982797200, 1995494400, 2014246800, 2026944000, 2045696400, 2058393600, 2077146000, 2090448000, 2108595600, 2121897600, 2140045200, 2153347200, 2172099600, 2184796800, 2203549200], changes_value: 3600 },
			{ display_name: '(UTC+04:00) Izhevsk, Samara (RTZ 3)', id: 64, base_utc: 14400, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+04:00) Port Louis', id: 65, base_utc: 14400, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+04:00) Tbilisi', id: 66, base_utc: 14400, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+04:00) Yerevan', id: 67, base_utc: 14400, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+04:30) Kabul', id: 68, base_utc: 16200, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+05:00) Ashgabat, Tashkent', id: 69, base_utc: 18000, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+05:00) Ekaterinburg (RTZ 4)', id: 70, base_utc: 18000, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+05:00) Islamabad, Karachi', id: 71, base_utc: 18000, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi', id: 72, base_utc: 19800, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+05:30) Sri Jayawardenepura', id: 73, base_utc: 19800, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+05:45) Kathmandu', id: 74, base_utc: 20700, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+06:00) Astana', id: 75, base_utc: 21600, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+06:00) Dhaka', id: 76, base_utc: 21600, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+06:00) Novosibirsk (RTZ 5)', id: 77, base_utc: 21600, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+06:30) Yangon (Rangoon)', id: 78, base_utc: 23400, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+07:00) Bangkok, Hanoi, Jakarta', id: 79, base_utc: 25200, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+07:00) Krasnoyarsk (RTZ 6)', id: 80, base_utc: 25200, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi', id: 81, base_utc: 28800, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+08:00) Irkutsk (RTZ 7)', id: 82, base_utc: 28800, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+08:00) Kuala Lumpur, Singapore', id: 83, base_utc: 28800, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+08:00) Perth', id: 84, base_utc: 28800, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+08:00) Taipei', id: 85, base_utc: 28800, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+08:00) Ulaanbaatar', id: 86, base_utc: 28800, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+09:00) Osaka, Sapporo, Tokyo', id: 87, base_utc: 32400, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+09:00) Seoul', id: 88, base_utc: 32400, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+09:00) Yakutsk (RTZ 8)', id: 89, base_utc: 32400, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+09:30) Adelaide', id: 90, base_utc: 34200, changes_time: [1443889800, 1428168600, 1475339400, 1459618200, 1506789000, 1491067800, 1538843400, 1522517400, 1570293000, 1554571800, 1601742600, 1586021400, 1633192200, 1617471000, 1664641800, 1648920600, 1696091400, 1680370200, 1728145800, 1712424600, 1759595400, 1743874200, 1791045000, 1775323800, 1822494600, 1806773400, 1853944200, 1838223000, 1885998600, 1869672600, 1917448200, 1901727000, 1948897800, 1933176600, 1980347400, 1964626200, 2011797000, 1996075800, 2043246600, 2027525400, 2075301000, 2058975000, 2106750600, 2091029400, 2138200200, 2122479000, 2169649800, 2153928600, 2201099400, 2185378200], changes_value: 3600 },
			{ display_name: '(UTC+09:30) Darwin', id: 91, base_utc: 34200, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+10:00) Brisbane', id: 92, base_utc: 36000, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+10:00) Canberra, Melbourne, Sydney', id: 93, base_utc: 36000, changes_time: [1443888000, 1428166800, 1475337600, 1459616400, 1506787200, 1491066000, 1538841600, 1522515600, 1570291200, 1554570000, 1601740800, 1586019600, 1633190400, 1617469200, 1664640000, 1648918800, 1696089600, 1680368400, 1728144000, 1712422800, 1759593600, 1743872400, 1791043200, 1775322000, 1822492800, 1806771600, 1853942400, 1838221200, 1885996800, 1869670800, 1917446400, 1901725200, 1948896000, 1933174800, 1980345600, 1964624400, 2011795200, 1996074000, 2043244800, 2027523600, 2075299200, 2058973200, 2106748800, 2091027600, 2138198400, 2122477200, 2169648000, 2153926800, 2201097600, 2185376400], changes_value: 3600 },
			{ display_name: '(UTC+10:00) Guam, Port Moresby', id: 94, base_utc: 36000, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+10:00) Hobart', id: 95, base_utc: 36000, changes_time: [1443888000, 1428166800, 1475337600, 1459616400, 1506787200, 1491066000, 1538841600, 1522515600, 1570291200, 1554570000, 1601740800, 1586019600, 1633190400, 1617469200, 1664640000, 1648918800, 1696089600, 1680368400, 1728144000, 1712422800, 1759593600, 1743872400, 1791043200, 1775322000, 1822492800, 1806771600, 1853942400, 1838221200, 1885996800, 1869670800, 1917446400, 1901725200, 1948896000, 1933174800, 1980345600, 1964624400, 2011795200, 1996074000, 2043244800, 2027523600, 2075299200, 2058973200, 2106748800, 2091027600, 2138198400, 2122477200, 2169648000, 2153926800, 2201097600, 2185376400], changes_value: 3600 },
			{ display_name: '(UTC+10:00) Magadan', id: 96, base_utc: 36000, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+10:00) Vladivostok, Magadan (RTZ 9)', id: 97, base_utc: 36000, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+11:00) Chokurdakh (RTZ 10)', id: 98, base_utc: 39600, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+11:00) Solomon Is., New Caledonia', id: 99, base_utc: 39600, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky (RTZ 11)', id: 100, base_utc: 43200, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+12:00) Auckland, Wellington', id: 101, base_utc: 43200, changes_time: [1443276000, 1428159600, 1474725600, 1459609200, 1506175200, 1491058800, 1538229600, 1522508400, 1569679200, 1554562800, 1601128800, 1586012400, 1632578400, 1617462000, 1664028000, 1648911600, 1695477600, 1680361200, 1727532000, 1712415600, 1758981600, 1743865200, 1790431200, 1775314800, 1821880800, 1806764400, 1853330400, 1838214000, 1885384800, 1869663600, 1916834400, 1901718000, 1948284000, 1933167600, 1979733600, 1964617200, 2011183200, 1996066800, 2042632800, 2027516400, 2074687200, 2058966000, 2106136800, 2091020400, 2137586400, 2122470000, 2169036000, 2153919600, 2200485600, 2185369200], changes_value: 3600 },
			{ display_name: '(UTC+12:00) Coordinated Universal Time+12', id: 102, base_utc: 43200, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+12:00) Fiji', id: 103, base_utc: 43200, changes_time: [1445695200, 1421506800, 1477144800, 1452956400, 1508594400, 1484406000, 1540648800, 1516460400, 1572098400, 1547910000, 1603548000, 1579359600, 1634997600, 1610809200, 1666447200, 1642258800, 1697896800, 1673708400, 1729951200, 1705762800, 1761400800, 1737212400, 1792850400, 1768662000, 1824300000, 1800111600, 1855749600, 1831561200, 1887804000, 1863615600, 1919253600, 1895065200, 1950703200, 1926514800, 1982152800, 1957964400, 2013602400, 1989414000, 2045052000, 2020863600, 2077106400, 2052918000, 2108556000, 2084367600, 2140005600, 2115817200, 2171455200, 2147266800, 2202904800, 2178716400], changes_value: 3600 },
			{ display_name: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old', id: 104, base_utc: 43200, changes_time: [1427551200, 1445698800, 1459000800, 1477753200, 1490450400, 1509202800, 1521900000, 1540652400, 1553954400, 1572102000, 1585404000, 1603551600, 1616853600, 1635606000, 1648303200, 1667055600, 1679752800, 1698505200, 1711807200, 1729954800, 1743256800, 1761404400, 1774706400, 1792854000, 1806156000, 1824908400, 1837605600, 1856358000, 1869055200, 1887807600, 1901109600, 1919257200, 1932559200, 1950706800, 1964008800, 1982761200, 1995458400, 2014210800, 2026908000, 2045660400, 2058357600, 2077110000, 2090412000, 2108559600, 2121861600, 2140009200, 2153311200, 2172063600, 2184760800, 2203513200], changes_value: 3600 },
			{ display_name: '(UTC+13:00) Nuku\'alofa', id: 105, base_utc: 46800, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 },
			{ display_name: '(UTC+13:00) Samoa', id: 106, base_utc: 46800, changes_time: [1443265200, 1428148800, 1474714800, 1459598400, 1506164400, 1491048000, 1538218800, 1522497600, 1569668400, 1554552000, 1601118000, 1586001600, 1632567600, 1617451200, 1664017200, 1648900800, 1695466800, 1680350400, 1727521200, 1712404800, 1758970800, 1743854400, 1790420400, 1775304000, 1821870000, 1806753600, 1853319600, 1838203200, 1885374000, 1869652800, 1916823600, 1901707200, 1948273200, 1933156800, 1979722800, 1964606400, 2011172400, 1996056000, 2042622000, 2027505600, 2074676400, 2058955200, 2106126000, 2091009600, 2137575600, 2122459200, 2169025200, 2153908800, 2200474800, 2185358400], changes_value: 3600 },
			{ display_name: '(UTC+14:00) Kiritimati Island', id: 107, base_utc: 50400, changes_time: [4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295, 4294967295], changes_value: 0 }
		];

	permissionFields: string[] = [
		'advanced_setup',
		'basic_setup',
		'calibration',
		'cm_testing',
		'debug_data',
		'device_config_attribute',
		'device_internal_attribute',
		'hw_cm_control',
		'in_test_mode',
		'networking_admin',
		'noc',
		'settings_admin',
		'wifi_admin',
	];

	defaultLatitude: string = "";
	defaultLongitude: string = "";

	maxMeterNumber: number = 32767;

	ctRatioOptions: { value: number, text: string }[] = [
		{ value: 50, text: '50A/0.333V' },
		{ value: 100, text: '100A/0.333V' },
		{ value: 150, text: '150A/0.333V' },
		{ value: 200, text: '200A/0.333V' },
		{ value: 400, text: '400A/0.333V' },
		{ value: 600, text: '600A/0.333V' },
	];

	ctRatioRogowskiType: { value: number, text: string}[]= [
		{ value: 1000, text: '1000A' },
	];

	nominalVoltageOptions: { text: string, value: number }[] = [
		{ text: '120/240', value: 120 },
		{ text: '208', value: 208 },
		{ text: '480', value: 480 },
		{ text: '600', value: 600 },
	];

	wiringModeNominalText: any = {
		0: 'L-L',
		1: 'L-L',
		2: 'L-L',
		3: 'L-N',
	};

	CLIENT_SOCKET_TYPE: any = {
		WIFI: 0,
		ETHERNET: 1,
		CELLULAR: 2,
	};

	cellularRegionOptions = [
		{ text: 'USA & Canada', data: 0 },
		{ text: 'USA', data: 1 },
		{ text: 'Canada', data: 2 },
	];

	RECEIVING_SHIPMENT_DEVICES_STATUS = {
		not_start: { status: 'not_start' },
		in_progress: { status: 'in_progress' },
		not_connected: { status: 'not_connected', key: 1 },
		cellular_not_connected: { status: 'cellular_not_connected', key: 2 },
		not_reachable: { status: 'not_reachable', key: 3 },
		warnings: { status: 'warnings', key: 4 },
		fw_request: { status: 'fw_request', key: 5 },
		factory_reset: { status: 'factory_reset', key: 6 },
		completed: { status: 'completed', key: 7 },
		no_cellular_iccid: { status: 'no_cellular_iccid', key: 8 },
		cellular_iccid_does_not_exist: { status: 'cellular_iccid_does_not_exist', key: 9 },
		device_not_cm_devices: { status: 'device_not_cm_devices', key: 10 },
		voltage_range_err: { status: 'voltage_range_err', key: 12 },
	};

	constructor(
		private translateService: TranslateService,
		private sitesService: SitesService,
	) {
		this.defaultLatitude = `${this.sitesService.defaultMapLocation.latitude}`;
		this.defaultLongitude = `${this.sitesService.defaultMapLocation.longitude}`;
	}

	getWiringModeOptions() {
		const options: any = {
			0: {
				value: 0,
				name: '3_phase_4_wire',
				text: this.translateService.instant('settings_device_settings.3_phase_4_wire'),
				enabledWiresOptions: [
					{ value: 0x07, text: `${this.translateService.instant('quick_view.phase_a')} / ${this.translateService.instant('quick_view.phase_b')} / ${this.translateService.instant('quick_view.phase_c')}` },
				],
				enabledWiresDefault: 0x07,
			},
			1: {
				value: 1,
				name: '3_phase_3_wire',
				text: this.translateService.instant('settings_device_settings.3_phase_3_wire'),
				enabledWiresOptions: [
					{ value: 0x07, text: `${this.translateService.instant('quick_view.phase_a')} / ${this.translateService.instant('quick_view.phase_b')} / ${this.translateService.instant('quick_view.phase_c')}` },
				],
				enabledWiresDefault: 0x07,
				voltageReferenceDefault: 'LINE_TO_LINE',
			},
			2: {
				value: 2,
				name: 'single_phase_3_wire',
				text: this.translateService.instant('settings_device_settings.single_phase_3_wire'),
				enabledWiresOptions: [
					{ value: 0x03, text: `${this.translateService.instant('quick_view.phase_a')} / ${this.translateService.instant('quick_view.phase_b')}` },
					{ value: 0x05, text: `${this.translateService.instant('quick_view.phase_a')} / ${this.translateService.instant('quick_view.phase_c')}` },
					{ value: 0x06, text: `${this.translateService.instant('quick_view.phase_b')} / ${this.translateService.instant('quick_view.phase_c')}` }
				],
				enabledWiresDefault: 0x03,
				wiringModeNote: this.translateService.instant('device.connect_single_phase_3_wire_note')
			},
			3: {
				value: 3,
				name: 'single_phase_2_wire',
				text: this.translateService.instant('settings_device_settings.single_phase_2_wire'),
				enabledWiresOptions: [
					{ value: 0x01, text: this.translateService.instant('quick_view.phase_a') },
					{ value: 0x02, text: this.translateService.instant('quick_view.phase_b') },
					{ value: 0x04, text: this.translateService.instant('quick_view.phase_c') }
				],
				enabledWiresDefault: 0x01,
				voltageReferenceDefault: 'LINE_TO_NEUTRAL',
				wiringModeNote: this.translateService.instant('device.connect_single_phase_2_wire_note')
			},
		};
		return options;
	}

	getMeterSubtractingOptions() {
		const options: any = [
			{ text: this.translateService.instant('ready_to_ship.accumulating'), value: 0 },
			{ text: this.translateService.instant('ready_to_ship.subtracting'), value: 1 },
		];
		return options;
	}

	getFeederMeterOptions() {
		const options: any = [
			{ text: this.translateService.instant('g.yes'), value: 1 },
			{ text: this.translateService.instant('g.no'), value: 0 },
		]
		return options;
	}

	mapCellularRssi(cellular_rssi: number) {
		switch(true) {
			case cellular_rssi == 0:
				  return this.translateService.instant('device.zero_cellular_rssi');
			   case cellular_rssi == 1:
				  return this.translateService.instant('device.one_cellular_rssi');
			case cellular_rssi >= 2 && cellular_rssi <= 30:
				return ((Math.abs(cellular_rssi) - 30) * 2 - 53) + " dBm";
			case cellular_rssi == 31:
				return this.translateService.instant('device.mid_cellular_rssi');
			case cellular_rssi > 31 && cellular_rssi <= 98:
				return this.translateService.instant('device.unknown_cellular_rssi', { cellular_rssi });
			case cellular_rssi == 99:
				return this.translateService.instant('device.cellular_rssi_unknown_or_undetectable');
			default:
				break;
		}
	}

	formattDeviceConfigData(device: any) {
		device.is_feeder = device.is_feeder_meter == 1 ? this.translateService.instant('g.yes') : this.translateService.instant('g.no');
		device.meter_subtracting = device.is_meter_subtracting == 1 ? this.translateService.instant('ready_to_ship.subtracting') : this.translateService.instant('ready_to_ship.accumulating');
		// nominal voltage
		const nominalVoltageOption: any = this.nominalVoltageOptions.find((option: any) => {
			return option.value == device.nominal_voltage;
		}) || {};
		device.nominal_voltage_type = nominalVoltageOption.text;
	
		// wiring mode
		device.wiring_mode_type = this.getWiringModeOptions()[device.wiring_mode].text;
	
		// ct ratio
		const deviceCtRatioOptions = [...this.ctRatioRogowskiType, ...this.ctRatioOptions];
		const ctRatioOption: any = deviceCtRatioOptions.find((option: any) => {
			return option.value == device.ct_ratio;
		}) || {};
		device.ct_ratio_type = ctRatioOption.text;
		device.empty_nbiot_bands = device.empty_nbiot_bands? this.translateService.instant('g.yes') : this.translateService.instant('g.no')
	}
}

export enum IpAssignmentValues {
	DHCP = 'DHCP',
	MANUAL = 'MANUAL',
}

export enum sitesFilterValues {
	ALL_SITES = 'all_sites',
	EMPTY_SITES = 'empty_sites',
	SPECIAL_SITES = 'special_sites',
	TEST_SITES = 'test_sites',
	EMPTY_ENTERPRISE = 'empty_enterprise',
	SMART_REBATES = 'smart_rebates',
}
