<div *ngIf="this.usersService.hasAccessPermission(null, 'noc', readPermission)">
	<h4>{{'note.reporting_noc_status' | translate}}</h4>
	<table class="table">
		<tr>
			<td *ngIf="this.usersService.hasAccessPermission(null, 'noc', writePermission)">
				<button type="button" class="btn btn-primary edit" (click)="showModal()">
					<i [ngClass]="'fa fa-edit fa-lg'" class="fa-fw" data-toggle="tooltip" data-placement="top" title="{{'g.edit' | translate}}"></i>
				</button>
			</td>
			<td >{{formattedDate}}</td>
			<td class="w-50">{{formattedNote || '' | translate}}</td>
		</tr>
	</table>
</div>

<app-sct-dialog [dialog]="editNoteDialog" [okText]="'g.submit'" [cancelText]="'g.cancel' | translate">
	<div dialog-content class="w-100 form-group">
		<textarea class="form-control" name="note" id="note" cols="30" rows="10" [(ngModel)]="newNote"></textarea>
	</div>
</app-sct-dialog>
